import Cache from '@aws-amplify/cache'
import {
  Row,
  Col,
  Typography,
} from 'antd'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import SslLogo from '../../assets/img/ssl.svg'
import StripeLogo from '../../assets/img/powered_by_stripe.svg'

import bannerImage from './banner.svg'
import bannerMobile from './banner_mobile.svg'
import IndexCTAButton from './IndexCTAButton'

const industryCacheKey = 'rsk-web.industry'

const IndexBanner = () => {
  const [industry, setIndustry] = useState('health')

  useEffect(() => {
    setIndustry(Cache.getItem(industryCacheKey) || 'health')
  }, [setIndustry])

  const bannerStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  }

  const bottom = {
    marginTop: '500px',
    paddingBottom: '20px',
  }

  const mobileBannerStyle = {
    minHeight: '250px',
    backgroundImage: `url(${bannerMobile})`,
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  }

  const CTAStyle = {
    backgroundColor: '#4e4e4e',
    border: '2px solid #4e4e4e',
    borderRadius: '50px',
    boxShadow: '15px 10px #e5e5e5',
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    fontSize: '20px',
    height: '80px',
    outline: 'none',
    width: '200px',
  }

  const CTAStyleMobile = {
    width: '200px',
    height: '80px',
    border: '2px solid #4e4e4e',
    borderRadius: '50px',
    backgroundColor: '#4e4e4e',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'white',
    boxShadow: '15px 10px #e5e5e5',
    marginTop: 200,
  }

  const trustSeal = {
    height: 30,
    margin: 15,
  }

  return (
    <>
      <div className="xs-hide sm-hide">
        <div style={bannerStyle}>
          <Row>
            <Col span={6} push={2} className="mt4 position-absolute">
              <Typography.Title level={2}>
                <FormattedMessage id="page.index.title" />
              </Typography.Title>
            </Col>
            <Col md={16}>
              <Row style={bottom} className="mb3 right" align="middle">
                <Col className="mr2">
                  <img src={SslLogo} alt="SSL Secure" style={trustSeal} />
                  <a
                    href="https://aws.amazon.com/what-is-cloud-computing"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="https://d0.awsstatic.com/logos/powered-by-aws.png"
                      alt="Powered by AWS Cloud Computing"
                      style={trustSeal}
                    />
                  </a>
                  <a
                    href="https://stripe.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={StripeLogo} alt="Powered By Stripe" style={trustSeal} />
                  </a>
                </Col>
                <Col>
                  <Link to={`${industry}`} state={{ industry }}>
                    <IndexCTAButton style={CTAStyle} />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="lg-hide md-hide">
        <Row justify="center" type="flex">
          <Col xs={18} className="m1">
            <Typography.Title level={3}>
              <FormattedMessage id="page.index.title" />
            </Typography.Title>
          </Col>
          <Col xs={24} className="center pb3" style={mobileBannerStyle}>
            <Link to={`${industry}`} state={{ industry }}>
              <IndexCTAButton style={CTAStyleMobile} />
            </Link>
          </Col>
          <Col xs={24} className="center pb3">
            <img src={SslLogo} alt="SSL Secure" style={trustSeal} />
            <a href="https://aws.amazon.com/what-is-cloud-computing">
              <img
                src="https://d0.awsstatic.com/logos/powered-by-aws.png"
                alt="Powered by AWS Cloud Computing"
                style={trustSeal}
              />
            </a>
            <a href="https://stripe.com">
              <img src={StripeLogo} alt="Powered By Stripe" style={trustSeal} />
            </a>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default IndexBanner
