import { Col, Row, Typography } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Step1 from './step1.svg'
import Step2 from './step2.svg'
import Step3 from './step3.svg'

const style = {
  backgroundColor: '#f0f2f5',
  width: '100%',
}

const hrStyle = {
  border: 'none',
  width: '100px',
  height: '2px',
  backgroundColor: '#333',
}
export default () => (
  <>
    <Row type="flex" justify="center" style={style}>
      <Col xs={16} md={8} className="center mt4">
        <Typography.Title level={3}>
          <FormattedMessage id="page.howitworks.title" />
        </Typography.Title>
        <hr style={hrStyle} />
      </Col>
    </Row>
    <Row type="flex" justify="center" className="py3" style={style}>
      <Col xs={12} md={6} className="center m1">
        <img alt="Find a grand prize" className="mb3" src={Step1} width={120} />
        <p className="pb0 mb0 bold">Find a grand prize</p>
        <p className="pt0 mt0 bold">You&apos;re interested in</p>
      </Col>
      <Col xs={12} md={6} className="center m1">
        <img alt="Participate in a contest or giveaway" className="mb3" src={Step2} width={120} />
        <p className="pb0 mb0 bold">Partiticipate in a</p>
        <p className="pt0 mt0 bold">Contest or Giveaway</p>
      </Col>
      <Col xs={12} md={6} className="center m1">
        <img alt="Winnners are chosen every week" className="mb3" src={Step3} width={120} />
        <p className="pb0 mb0 bold">Winners are chosen</p>
        <p className="pt0 mt0 bold">Every week</p>
      </Col>
    </Row>
  </>
)
