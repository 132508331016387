import { Col, Row, Typography } from 'antd'
import React from 'react'

export default () => {
  const hrStyle = {
    backgroundColor: '#333',
    border: 'none',
    height: '2px',
    width: '150px',
  }

  return (
    <div>
      <Row type="flex" justify="center">
        <Col xs={16} md={8} className="center mt4">
          <Typography.Title level={3}>
            TESTIMONIALS
          </Typography.Title>
          <hr style={hrStyle} />
        </Col>
      </Row>
      <Row type="flex" justify="center mb4">
        <Col xs={12} md={6} className="center m1">
          <p className="bold left-align">
            &quot;I&apos;m happy I participated. Raffskie was fun, easy and reliable. I won a great prize&quot;
          </p>
          <p className="right bold">-Richard Shimo</p>
        </Col>
        <Col xs={12} md={6} className="center m1">
          <p className="bold left-align">
            &quot;Raffskie was simple to use and was an effective engagement tool. After all everyone
            enjoys winning something&quot;
          </p>
          <p className="right bold">-Eaglequest Golf</p>
        </Col>
        <Col xs={12} md={6} className="center m1">
          <p className="bold left-align">
            &quot;If you&apos;re looking to add value to your customer relationship then Raffskie
            has a powerful platform.&quot;
          </p>
          <p className="right bold">-Clipshotz</p>
        </Col>
      </Row>
    </div>
  )
}
