import React from 'react'
import IndexBanner from '../components/IndexBanner'
import Layout from '../components/Layout'

import IndexHowItWorks from '../components/IndexHowItWorks'
import IndexMeetOurWinners from '../components/IndexMeetOurWinners'

const IndexPage = () => (
  <Layout customStyle={{ backgroundColor: 'white', marginTop: '64px' }}>
    {/* banner image */}
    <IndexBanner />
    {/* how it works section */}
    <IndexHowItWorks />
    {/* Meet our winners section */}
    <IndexMeetOurWinners />
  </Layout>
)

export default IndexPage
